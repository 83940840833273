import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import CreateIcon from "@material-ui/icons/Create";
import SendIcon from '@material-ui/icons/Send';
import CodeIcon from '@material-ui/icons/Code';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {NavLink, Link, Route} from 'react-router-dom'


const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function Menu() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom"
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <>
      <List>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
            <NavLink to="/" style={{textDecoration:'none', color:'#000',marginLeft:'32px'}}>Home Page</NavLink> 
          </ListItemIcon>
          <ListItemText />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <CreateIcon />
            <NavLink to="/constructor" style={{textDecoration:'none', color:'#000',marginLeft:'32px'}}>Create your product</NavLink> 
          </ListItemIcon>
          <ListItemText/>
        </ListItem>
        {/* <ListItem button>
          <ListItemIcon>
            <AttachMoneyIcon />
            <NavLink to="/calculator" style={{textDecoration:'none', color:'#000',marginLeft:'32px'}}>Calculator</NavLink> 
          </ListItemIcon>
          <ListItemText  />
        </ListItem> */}
        <ListItem button>
          <ListItemIcon>
            <SendIcon />
            <NavLink to="/contact" style={{textDecoration:'none', color:'#000',marginLeft:'32px'}}>Contact with us</NavLink> 
          </ListItemIcon>
          <ListItemText />
        </ListItem>
        {/* <ListItem button>
          <ListItemIcon>
            <SendIcon />
            <a href="http://dyplomy.drukurs.pl/" style={{textDecoration:'none', color:'#000',marginLeft:'32px'}}>Dyplomy</a> 
          </ListItemIcon>
          <ListItemText />
        </ListItem> */}
        <ListItem button>
          <ListItemIcon>
            <CodeIcon />
            <a href="https://www.facebook.com/evgeni.ishimov.3/" style={{textDecoration:'none', color:'#000',marginLeft:'32px'}}>Developer</a> 
          </ListItemIcon>
          <ListItemText />
        </ListItem>
      </List>
      </>
    </div>
  );

  return (
    <div>
      {["left"].map(anchor => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <MenuIcon />
          </IconButton>

          <Drawer
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
