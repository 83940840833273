import React from "react";
import { Rnd } from "react-rnd";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import Draggable from "react-draggable";

const RenderImageItem = ({projectImages}) => {
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);

  const [width, setWidth] = React.useState(300);
  const [height, setHeight] = React.useState(200);

  

  return (
    <>
      <Rnd
        onResizeStart={(e) => {
          e.stopPropagation();
          // setResizing(true);
        }}
        onResizeStop={(e, direction, ref, d) => {
          e.stopPropagation();
          // setResizing(false);
          setWidth(width + d.width);
          setHeight(height + d.height);
        }}
        // lockAspectRatio={true}
        bounds=".parents"
      >
        <img
          onDragStart={(e) => {
            e.preventDefault();
          }}
          style={{
            width: width + "px",
            height: height + "px",
          }}
          src={projectImages.data_url}
          alt="Your project"
          // className={uploadImage ? "" : "none"}
        />
      </Rnd>
    </>
  );
};

export default RenderImageItem;
