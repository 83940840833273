export const ReducerCalculator = (state, action) => {
	switch (action.type) {
		case 'CHANGE_AMOUNT' :	
		return {
			...state,
			AmountField: action.payload,
		}
		case 'CHANGE_SIZE' :
		return {
			...state,
			ActiveOptionSize: action.payload,
		}
		case 'CHANGE_MATERIAL' :
		return {
			...state,
			ActiveOptionMaterial: action.payload,
		}
		case 'CHANGE_CORNERS' :
		return {
			...state,
			Corners: action.payload,
		}

        default: 
		return state
    }
}
