import React, { useState } from "react";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import Draggable from "react-draggable";
import "./Field.css";
import RenderImages from "../RenderImages/RenderImages";
import ImageUpload from "../ImageUpload/ImageUpload";

// import { makeStyles } from "@material-ui/core/styles";

import { Resizable } from "re-resizable";
import { Rnd } from "react-rnd";

export const Field = React.memo(() => {
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);
  const inputs = stateInputs.state.inputs;
  const backgroundColor = stateInputs.state.bgColor;
  const QRCode = stateInputs.state.QRCode;
  var QRcode = require("qrcode.react");
  const [qrWidth, setQrWidth] = React.useState(128);
  const [qrHeight, setQrHeight] = React.useState(128);
  // console.log(backgroundColor);
  const uploadImage = stateInputs.state.ImageUpload.imagePreviewUrl;
  const uploadFile = stateInputs.state.ImageUpload.file;
  console.log(uploadFile);
  // const [isClicked, setIsCliecked] = useState(false)
  // const useStyles = makeStyles((theme) => ({
  //   margin: {
  //     top: "45.3514px",
  //     left: "55.3514px",
  //     width: "560.297px",
  //     height: "300.297px",
  //     position: 'absolute',
  //     border:'1px dashed #fff',
  //   },
  // }));
  // Resizable
  // const classes = useStyles();
  const [width, setWidth] = React.useState(300);
  const [height, setHeight] = React.useState(200);
  // const  imgClass = cx({
  //   const background = `${stateInputs.state.bgColor === 'gray' ? "gray" : `${backgroundColor}`},
  //  ${stateInputs.state.bgCard === 'gray' ? `URL(${stateInputs.state.bgCard})` : "transparent"}`

  //   console.log(background)
  //   });

  // const getBackgound = () => {
  //   if (
  //     stateInputs.state.bgCard === { gray: require("../../assets/img/1.png") }
  //   ) {
  //     return `URL(${stateInputs.state.bgCard})` || `${backgroundColor}`;
  //   } else if (
  //     stateInputs.state.bgCard === { gray: require("../../assets/img/1.png") }
  //   ) {
  //     return `${backgroundColor}`;
  //   } else {
  //     return `URL(${stateInputs.state.bgCard})`;
  //   }
  // };
  // const getBackgound =() => {
  //   if(stateInputs.state.backgrounds[stateInputs.state.bgCard] === 'gray') {
  //     return stateInputs.state.backgrounds[stateInputs.state.bgCard] || `${backgroundColor}`
  //   }
  //   else {
  //     return `URL(${stateInputs.state.bgCard})`
  //   }
  // }
  const getBackground = () => {
    //console.log(stateInputs.state.backgrounds);
    return (
      (stateInputs.state.bgColor === "gray" && {
        backgroundImage: `url(${stateInputs.state.bgCard})`,
      }) || { backgroundColor: stateInputs.state.bgColor }
    );
  };

  return (
    <div>
      <div className="image__container">
        <ImageUpload />
      </div>
      <div className="field__wrapper">
        <div
          className="parents"
          id="parents"
          style={{
            ...getBackground(),
            // backgroundColor:
            //   stateInputs.state.bgColor === "gray" ? "gray" : `${backgroundColor}`,
            // background: `${stateInputs.state.bgColor === 'gray' ? "gray" : `${backgroundColor}},
            // ${stateInputs.state.bgCard === 'gray' ? `URL(${stateInputs.state.bgCard})` : "transparent"}`,
            // background: getBackground(),
            // color: "#fff",
            // width: "580px",
            // height: "333px",
            // // height: "79%",
            // // width: "100%",
            // position: "relative",
          }}
        >
          <div
            // style={{
            //   // width: "91.5%",
            //   width: "549px",
            //   height: "100%",
            //   position: 'relative',
            //   left: '16px'
            // }}
            className="field__container"
          >
            {/* <span className={classes.margin}></span> */}
            {inputs.map((input, index) => {
              return (
                // <div className="drag-container">
                <Draggable
                  defaultPosition={
                    window.innerWidth < 768
                      ? { x: 180, y: 70 }
                      : { x: 350, y: 130 }
                  }
                  key={index}
                  // bounds={{ top: -1, left: 0, right: 610, bottom: 290 }}
                  bounds="parent"
                  // offsetParent="parent"
                  // grid={[5, 5]}
                  scale={1}
                >
                  <Resizable
                    onResizeStart={(e) => {
                      e.stopPropagation();
                    }}
                    defaultSize={{
                      width: 150,
                    }}
                    maxWidth={500}
                    enable={{
                      right: true,
                      bottom: false,
                      left: false,
                      top: false,
                    }}
                    style={{
                      zIndex: input.zIndex ? "1" : "0",
                    }}
                  >
                    <div
                      key={index}
                      style={{
                        fontWeight: input.bold ? "700" : "400",
                        fontStyle: input.style ? "italic" : "normal",
                        textDecoration: input.underline ? "underline" : "none",
                        fontSize: `${input.fontSize}px`,
                        color: input.color,
                        fontFamily: input.activeFontFamily,
                        border: "1px solid transparent",
                        borderColor:
                          stateInputs.state.activeInput === input.id
                            ? "#fff"
                            : "transparent",
                      }}
                      onClick={() => {
                        dispatch({
                          type: "ACTIVE_INPUTS",
                          payload: {
                            id: index,
                          },
                        });
                      }}
                    >
                      {input.text}
                    </div>
                  </Resizable>
                </Draggable>
              );
            })}

            <RenderImages />

            <div>
              <Rnd
                onResizeStart={(e) => {
                  e.stopPropagation();
                  // setResizing(true);
                }}
                onResizeStop={(e, direction, ref, d) => {
                  e.stopPropagation();
                  // setResizing(false);
                  setWidth(width + d.width);
                  setHeight(height + d.height);
                }}
                // lockAspectRatio={true}
                bounds=".parents"
                // enableResizing={{
                //   top: false,
                //   left: false,
                //   right: false,
                //   bottom: false,
                //   topLeft: false,
                //   topRight: false,
                //   bottomLeft: false,
                //   bottomRight: true
                // }}
              >
                {/* {
                  <img
                    onDragStart={(e) => {
                      e.preventDefault();
                    }}
                    style={{
                      width: width + "px",
                      height: height + "px",
                    }}
                    src={uploadImage}
                    alt="Your project image"
                    className={uploadImage ? "" : "none"}
                  />
                } */}
              </Rnd>
              <Rnd
                onResizeStart={(e) => {
                  e.stopPropagation();
                  // setResizing(true);
                }}
                onResizeStop={(e, direction, ref, d) => {
                  e.stopPropagation();
                  // setResizing(false);
                  setQrWidth(qrWidth + d.width);
                  setQrHeight(qrHeight + d.height);
                }}
                lockAspectRatio={true}
                bounds=".parents"
              >
                <QRcode
                  onDragStart={(e) => {
                    e.preventDefault();
                  }}
                  value={QRCode}
                  style={{
                    width: qrWidth + "px",
                    height: qrHeight + "px",
                  }}
                  className={!QRCode ? "none" : "qrCode"}
                />
              </Rnd>
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
});
