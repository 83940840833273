const stateInput = {
  ImageUpload: {
    file: "",
    imagePreviewUrl: "",
  },
  disableTools: true,
  activeInput: 0,
  ProjectImages: [],
  RenderImages: [],
  bgCard: require('../../assets/img/drukurs.png'),
  QRCode: "",
  // bgCard: 'gray',
  bgColor: "gray",
  toFront: true,
  disabledButtonInput: false,
  imagesSlider: [
    require('../../assets/img/1.png'),
    require('../../assets/img/2.png'),
    require('../../assets/img/3.png'),
    require('../../assets/img/4.png'),
    require('../../assets/img/5.png'),
    require('../../assets/img/6.png'),
    require('../../assets/img/8.png'),
    require('../../assets/img/9.png'),
    require('../../assets/img/10.png'),
    require('../../assets/img/11.png'),
    require('../../assets/img/12.png'),
    require('../../assets/img/13.png'),
    require('../../assets/img/14.png'),
    require('../../assets/img/15.png'),
  ],
  inputs: [
    {
      id: 0,
      text: "Drukurs",
      bold: false,
      style: false,
      underline: false,
      fontSize: 20,
      color: "#225699",
      activeFontFamily: "Open Sans",
      remove: false,
    },
    {
      id: 1,
      text: "505226709",
      bold: false,
      style: false,
      underline: false,
      fontSize: 14,
      color: "#225699",
      activeFontFamily: "Open Sans",
      remove: false,
    },
    {
      id: 2,
      text: "ul. Warsztatowa 10",
      bold: false,
      style: false,
      underline: false,
      fontSize: 14,
      color: "#225699",
      activeFontFamily: "Open Sans",
      remove: false,
    },
    {
      id: 3,
      text: "21-040 Świdnik",
      bold: false,
      style: false,
      underline: false,
      fontSize: 14,
      color: "#225699",
      activeFontFamily: "Open Sans",
      remove: false,
    },
    {
      id: 4,
      text: "drukurs@gmail.com",
      bold: false,
      style: false,
      underline: false,
      fontSize: 14,
      color: "#225699",
      activeFontFamily: "Open Sans",
      remove: false,
    },
  ],
};
export default stateInput;
