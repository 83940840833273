import React from "react";
// import Input from "../../components/Input/Input";
import {SelectOption} from "../../components/Select/Select"
import {SelectMaterial} from '../../components/SelectMaterial/SelectMaterial'
import {ContextCalculator} from '../../context/Calculator/ContextCalculator'

// UI
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';

const OptionList = (e) => {

  const { state, dispatch } = React.useContext(ContextCalculator)
  
    
  const changeAmountField = (e) => {
    dispatch({
      type: 'CHANGE_AMOUNT',
      payload: e.target.value,
    })
  }
  
  const changeMaterial = (e) => {
    dispatch({
      type: 'CHANGE_MATERIAL',
      payload: e.target.value,
    })
  }

  const changeSize = (e) => {
    dispatch({
      type: 'CHANGE_SIZE',
      payload: e.target.value,
    })
  }
  
  const changeCorners = (e) => {
    dispatch({
      type: 'CHANGE_CORNERS',
      payload: true,
    })
  }

  return (
    <div style={{border: '1x solid #000'}}>
      <TextField onInput={changeAmountField} defaultValue="1" id="outlined-basic" label="Amount" variant="outlined" type="number"  size="small" style={{width:'168px', marginBottom: '10px'}} />
      <SelectOption onChange={changeSize} value={state.ActiveOptionSize}/>
      <SelectMaterial onChange={changeMaterial}/>
      <div style={{display: 'flex', alignItems:'center', marginTop:5}}>
          <label htmlFor="roundingCorners">Rounding Corners</label>
      <Checkbox onChange={changeCorners} color="primary" type="checkbox" id="roundingCorners" style={{width:'20px'}} value={state.Corners} />
      </div>
    </div>
  );
};

export default OptionList;
