import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ContextInput} from './../../context/Input/ContextInput.jsx'


export const ControlledCarousel = () => {
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 3,
      adaptiveHeight: false  
    }

    const changeBgCard = (slide) => {
      dispatch({
        type: 'GHANGE_BGCARD',
        payload: slide
      })
    }
    // console.log(stateInputs.state.imagesSlider)
    return (
      <div style={{height: 100}}>
        <Slider {...settings}>
          {stateInputs.state.imagesSlider.map((slide, index) => {
            return <div key={index} style={{marginTop: '10%'}}>
                    <div style={{height: 150, overflow: 'hidden', display: 'flex', alignItems: 'center', marginTop:'0px', padding:'5px'}}
                    
                    onClick={() => changeBgCard(slide) }>
                      
                      <img style={{width: '100%'}} src={slide} alt=""/>
                    </div>            
                  </div>
          })} 
        </Slider>
      </div>
    );
}

