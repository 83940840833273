import React from "react";
// import { SelectOption } from "../../components/Select/Select";
// import { SelectMaterial } from "../../components/SelectMaterial/SelectMaterial";
// import { ReducerCalculator } from "../../context/Calculator/ReducerCalculator";
// import { StateCalculator } from "../../context/Calculator/StateCalculator";
import { ContextCalculator } from "../../context/Calculator/ContextCalculator";

// UI

const PriceList = () => {
  const { state } = React.useContext(ContextCalculator);
  const corners = state.Corners;

  return (
    <div>
      <div>
        <p>Size:{state.ActiveOptionSize}</p>
        <p>Amount: 100 x {state.AmountField}</p>
      </div>
      <div style={{ width: 215 }}>
        <h1>Price:</h1>
        <p>Paper:{state.ActiveOptionMaterial}</p>
        <p>Rounding Corners: {corners ? '50' : null}</p>
        <h1>Total:</h1>
      </div>
    </div>
  );
};

export default PriceList;
