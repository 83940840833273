import React, { useReducer } from "react";
import PriceList from "../../modules/PriceList/PriceList";
import OptionList from "../../modules/OptionList/OptionList";
import { ReducerCalculator } from "../../context/Calculator/ReducerCalculator";
import { StateCalculator } from "../../context/Calculator/StateCalculator";
import { ContextCalculator } from "../../context/Calculator/ContextCalculator";
import '../Calculator/Calculator.css'

// UI
import Drawer from '../../modules/Drawer/Drawer'


export default React.memo(() => {
  const [state, dispatch] = useReducer(ReducerCalculator, StateCalculator);
  return (
    <ContextCalculator.Provider value={{ state, dispatch }}>
      <div>
      <Drawer />
        <h1 style={{textAlign: 'center'}}>Calculator</h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="OptionList">
            <OptionList />
          </div>
          <div className="PriceList">
            <PriceList />
          </div>
        </div>
      </div>
    </ContextCalculator.Provider>
  );
});
