import React, { useState } from "react";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import { PhotoshopPicker, ChromePicker } from "react-color";
import FontPicker from "font-picker-react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import "./Tools.css";
import Button from "@material-ui/core/Button";
// import DarkModeToggle from "react-dark-mode-toggle";

import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
//Material UI
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DeleteButton from "../DeleteButton/DeleteButton";
// import UploadButton from "../../components/UploadButton/UploadButton";
import ImageUpload from "../ImageUpload/ImageUpload";
import Arrange from "../Arrange/Arrange";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import ColorizeIcon from "@material-ui/icons/Colorize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';

export const Tools = React.memo(() => {
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);
  const inputs = stateInputs.state.inputs;
  // const bgColor = stateInputs.state.bgColor
  const [font, setFont] = React.useState("Open Sans");

  const changeBold = () => {
    dispatch({
      type: "CHAHGE_BOLD",
    });
  };
  const changeStyle = () => {
    dispatch({
      type: "CHAHGE_STYLE",
    });
  };
  const changeUnderline = () => {
    dispatch({
      type: "CHAHGE_UNDERLINE",
    });
  };
  const changeFontSize = (e) => {
    e.preventDefault();
    dispatch({
      type: "CHAHGE_FONTSIZE",
      payload: e.target.value,
    });
  };
  const changeColor = (color) => {
    dispatch({
      type: "CHAHGE_COLOR",
      payload: color,
    });
  };
  const changeBackground = (color) => {
    dispatch({
      type: "CHAHGE_BACKGROUND",
      payload: color,
    });
  };
  const handleFontChange = (nextFont) => {
    setFont(nextFont.family);
    dispatch({
      type: "CHANGE_FONT",
      payload: nextFont.family,
    });
  };

  const [stateColor, setStateColor] = useState({
    displayColorPicker: false,
  });
  const [stateBackground, setStateBackground] = useState({
    displayColorPicker: false,
  });
  const handleClickColor = () => {
    setStateColor({ displayColorPicker: !stateColor.displayColorPicker });
  };
  const handleBackgroundClose = () => {
    setStateBackground({ displayColorPicker: false });
  };

  const handleColorClose = () => {
    setStateColor({ displayColorPicke: false });
  };

  const handleClickBackground = () => {
    setStateBackground({
      displayColorPicker: !stateBackground.displayColorPicker,
    });
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  // console.log(stateInputs);
  // const uploadIcon = <FontAwesomeIcon icon={faFileUpload} />;
  // const [pictures, setPictures] = useState([]);

  return (
    <div className="tools__navigation">
      <Button onClick={changeBold}>B</Button>
      <Button onClick={changeStyle}>I</Button>
      <Button onClick={changeUnderline}>U</Button>
      {/* <Tooltip title="change font size" arrow> */}
      <FormControl>
        <Select
          onChange={changeFontSize}
          style={{ minWidth: "60px", textAlign: "center" }}
          // value={inputs[stateInputs.state.activeInput].fontSize}
          defaultValue="14"
        >
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="12">12</MenuItem>
          <MenuItem value="14">14</MenuItem>
          <MenuItem value="16">16</MenuItem>
          <MenuItem value="18">18</MenuItem>
          <MenuItem value="20">20</MenuItem>
          <MenuItem value="22">22</MenuItem>
          <MenuItem value="24">24</MenuItem>
          <MenuItem value="26">26</MenuItem>
          <MenuItem value="28">28</MenuItem>
          <MenuItem value="30">30</MenuItem>
          <MenuItem value="32">32</MenuItem>
          <MenuItem value="32">32</MenuItem>
          <MenuItem value="34">34</MenuItem>
          <MenuItem value="36">36</MenuItem>
          <MenuItem value="38">38</MenuItem>
          <MenuItem value="40">40</MenuItem>
          <MenuItem value="46">46</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="55">55</MenuItem>
          <MenuItem value="60">60</MenuItem>
        </Select>
      </FormControl>
      {/* </Tooltip> */}

      {/* <UploadButton></UploadButton> */}
      {/* <ImageUpload /> */}
      <DeleteButton />
      <Arrange />
      {/* <DarkModeToggle
      onChange={setIsDarkMode}
      checked={isDarkMode}
      
      size={80}
        /> */}

      {/* <Button onClick={()=>{
        <Alert>1213</Alert>
      }}>error</Button> */}
      {/* <ImageUploader        
        withIcon={true}
        onChange={onDrop}
        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
        maxFileSize={5242880}
        withPreview={true}
      /> */}

      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        
      </div> */}

      <div>
        <Tooltip title="background color" arrow>
          <IconButton onClick={handleClickBackground} color="primary">
            <FormatColorFillIcon />
          </IconButton>
        </Tooltip>

        {stateBackground.displayColorPicker ? (
          <div style={popover}>
            {/* {" "}
            : */}
            <div style={cover} onClick={handleBackgroundClose} />
            <ChromePicker
              color={stateInputs.state.bgColor}
              onChange={changeBackground}
            />
          </div>
        ) : null}
      </div>

      <div>
        <Tooltip title="text color" arrow>
          <IconButton color="primary" onClick={handleClickColor}>
            <FormatColorTextIcon />
          </IconButton>
        </Tooltip>
        {stateColor.displayColorPicker ? (
          <div style={popover}>
            {/* {" "}
            : */}
            <div style={cover} onClick={handleColorClose} />
            <PhotoshopPicker
              color={inputs[stateInputs.state.activeInput].color}
              onChange={changeColor}
            />
          </div>
        ) : null}
      </div>

      {/* <div className="box">
      <div>
        <Tooltip title="delete image" arrow>
          <IconButton
            aria-label="delete"
            className={classes.color}
            onClick={deleteHandler}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="delete text" arrow>
          <IconButton
            aria-label="delete"
            className={classes.color}
            onClick={deleteItems}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div> */}

      <div>
        <FontPicker
          apiKey="AIzaSyCMUflPd0R0kEZWBegSTvkVE5zb7Uq55ks"
          activeFontFamily={font}
          onChange={(nextFont) => handleFontChange(nextFont)}
          limit="300"
          sort="popularity"
        />
      </div>
    </div>
  );
});
