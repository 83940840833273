import React from "react";
// import IconButton from "@material-ui/core/IconButton";
// import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { ContextInput } from "../../context/Input/ContextInput";
import ImageUploading from "react-images-uploading";
import Button from "@material-ui/core/Button";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";


import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import "./style.css";

const ImageUpload = () => {
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);
  const maxNumber = 69;
  const ProjectImages = stateInputs.state.ProjectImages;
  // console.log(ProjectImages);
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // debugger
    // console.log(imageList);
    // setImages(imageList);
    

    dispatch({
      type: "PROJECT_IMAGE",
      payload: imageList,
    });
  };

  const onUpload = (imageList, addUpdateIndex, e) => {
    dispatch({
      type: "RENDER_IMAGE",
      payload: stateInputs.state.ProjectImages,
    });
  };

  // const [img] = useImage(image.src);

  return (
    // {stateInputs.state.ProjectImages.map((images, index)=> {
    //   return()
    // })}
    <div className="image__wrapper">
      <ImageUploading
        multiple
        value={stateInputs.state.ProjectImages}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        // isDragging={true}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <Button
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
              variant="contained"
              startIcon={<CloudUploadIcon />}
              color="primary"
              size="large"
              fullWidth="true"
            >
              Click or Drop here
            </Button>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {ProjectImages.map((image, index) => (
              <div key={index} className="image-item">
                <img
                  src={image["data_url"]}
                  alt=""
                  width="100"
                  draggable="true"
                />
                <div className="image-item__btn-wrapper">
                  {/* <Button onClick={onUpload}>Render</Button> */}

                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => onImageRemove(index)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUpload;
