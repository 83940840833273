import React, { useEffect } from "react";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import "../Inputs/Inputs.css";
import Drawer from "../Drawer/Drawer";

//UI
import Button from "@material-ui/core/Button";
import { useToasts } from "react-toast-notifications";
import "bootstrap/dist/css/bootstrap.min.css";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";


import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";


const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    border: "1px solid #e1e4e8",
    marginTop: "5px",
    width: "auto",
    padding: "6px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      boxShadow: "0 0 0 3px rgba(3,102,214,.3)",
      border: "1px solid #0366d6",
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

export const Inputs = React.memo(() => {
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);
  const inputs = stateInputs.state.inputs;
  const { addToast } = useToasts();
  const [open, setOpen] = React.useState(false);
  const [qrCode, setQRCode] = React.useState("");

  const addQRCode = () => {
    // console.log(qrCode, 'dispatch')
    dispatch({
      type: "QR_CODE",
      payload: qrCode
    });
    setOpen(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const alert = useAlert();

  const addInputText = () => {
    if (inputs.length < 8) {
      dispatch({
        type: "ADD_INPUT",
      });
    } else {
      addToast("You used the maximum amount of text", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const addInputCheck = () => {
    if (window.innerWidth < 767) {
      dispatch({
        type: "CHECK_INPUT",
      });
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      dispatch({
        type: "CHECK_INPUT",
      });
    }
  }, []);

  return (
    <div>
      <div>
        <Drawer />
      </div>
      <div className="MainInput">
        {inputs.map((input, index) => {
          return (
            <BootstrapInput
              // id="filled-basic"
              label="Information"
              name="text1"
              value={input.text}
              key={index}
              onChange={(e) => {
                dispatch({
                  type: "CHANGE_INPUTS",
                  payload: {
                    id: index,
                    value: e.target.value,
                  },
                });
              }}
              onClick={() => {
                dispatch({
                  type: "ACTIVE_INPUTS",
                  payload: {
                    id: index,
                  },
                });
              }}
            />
          );
        })}
        <Button
          variant="contained"
          onClick={addInputText}
          color="primary"
          style={{ margin: "10px 0px 0px 0px" }}
          disabled={
            stateInputs.state.disabledButtonInput && inputs.length > 4
              ? true
              : false
          }
          // disabled={ ? true : false}
        >
          Create text
        </Button>
        <div className="qrCode__container">
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Dodaj kod QR
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Generuj kod QR</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Wprowadź tekst który chcesz zakodować
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="qrCode"
                label="Wpisz link"
                type="url"
                fullWidth
                onChange={(e) => {
                  setQRCode(e.target.value)
                  // console.log(qrCode)
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                anuluj
              </Button>
              <Button
                onClick={addQRCode}
                color="primary"
              >
                Wstaw kod
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
});
