import React from "react";
// import styled from "styled-components";
import { ContextCalculator } from "../../context/Calculator/ContextCalculator";

// import classes from "../Select/Select.css";

//UI
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


export const SelectOption = ({changeInput}) => {

  const { state, dispatch } = React.useContext(ContextCalculator);


  const optionSize = state.optionSize;


  const changeSize = (e) => {
    console.log(state)
    dispatch({
      type: 'CHANGE_SIZE',
      payload: e.target.value,
    })
  }

  return (
    <Select 
      style={{ display: "block", width: "168px", paddingRight: "0 !important" }}
      onChange={changeSize}
      defaultValue="85x50"
    >
      {optionSize.map((optionItem, index) => {
        return (
          <MenuItem key={index} value={optionItem}>
            {optionItem}
          </MenuItem>
        );
      })}
    </Select>
  );
};
