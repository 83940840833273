import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBInput,
} from "mdbreact";

import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import { Button } from "@material-ui/core";
import {
  // createMuiTheme,
  withStyles,
  // makeStyles,
  // ThemeProvider,
} from "@material-ui/core/styles";

import "./style.css";

const ContactPage = () => {
  // const BootstrapButton = withStyles({
  //   root: {
  //     boxShadow: "none",
  //     textTransform: "none",
  //     fontSize: 18,
  //     padding: "10px 12px",
  //     border: "1px solid",
  //     lineHeight: 1.5,
  //     backgroundColor: "#0063cc",
  //     borderColor: "#0063cc",
  //     fontFamily: [
  //       "-apple-system",
  //       "BlinkMacSystemFont",
  //       '"Segoe UI"',
  //       "Roboto",
  //       '"Helvetica Neue"',
  //       "Arial",
  //       "sans-serif",
  //       '"Apple Color Emoji"',
  //       '"Segoe UI Emoji"',
  //       '"Segoe UI Symbol"',
  //     ].join(","),
  //     "&:hover": {
  //       backgroundColor: "#0069d9",
  //       borderColor: "#0062cc",
  //       boxShadow: "none",
  //     },
  //     "&:active": {
  //       boxShadow: "none",
  //       backgroundColor: "#0062cc",
  //       borderColor: "#005cbf",
  //     },
  //     "&:focus": {
  //       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.9)",
  //     },
  //   },
  // })(Button);

  const [state, setState] = useState({
    name: "",
    email: "",
    file: "",
    message: "",
  });

  // const submitHandler = (e) => {
  //   e.target.className += " was-validated";
  //   // e.preventDefault();
  //   // document.myForm.submit();
  //   console.log(e.target.className);
  //   if (e.target.classList.contains("valid-feedback")) {
  //     document.myForm.submit();
  //   }
  // };

  const changeHandler = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <section className="my-5">
      <h2 className="h1-responsive font-weight-bold text-center my-5">
        Contact us
      </h2>
      {/* <p className="text-center w-responsive mx-auto pb-5">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
        error amet numquam iure provident voluptate esse quasi, veritatis
        totam voluptas nostrum quisquam eum porro a pariatur veniam.
      </p> */}
      <MDBRow>
        <MDBCol lg="5" className="lg-0 mb-4">
          <MDBCard>
            <MDBCardBody>
              <form
                enctype="multipart/form-data"
                action="form.php"
                method="post"
                // className="needs-validation"
                // noValidate
                id="myForm"
                name="myForm"
              >
                <div className="form-header blue accent-1">
                  <h3 className="mt-2">
                    <MDBIcon icon="envelope" /> Write to us:
                  </h3>
                </div>
                <p className="dark-grey-text">Send Your Project 🔥</p>
                <div className="md-form">
                  <MDBInput
                    icon="user"
                    label="Your name"
                    iconClass="grey-text"
                    type="text"
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={changeHandler}
                    className="form-control"
                    required
                  />
                  {/* <div className="valid-feedback">Looks good!</div> */}
                </div>
                <div className="md-form">
                  <MDBInput
                    icon="envelope"
                    label="Your email"
                    iconClass="grey-text"
                    type="email"
                    id="email"
                    name="email"
                    value={state.email}
                    onChange={changeHandler}
                    className="form-control"
                    required
                  />
                  {/* <div className="invalid-feedback">
                    Please provide a valid email.
                  </div> */}
                  {/* <div className="valid-feedback">Looks good!</div> */}
                </div>
                <div className="md-form">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        id="inputGroupFileAddon01"
                      >
                        Upload
                      </span>
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input form-control"
                        name="file"
                        id="file"
                        aria-describedby="inputGroupFileAddon01"
                        required
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile01"
                      >
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>
                <div className="md-form mt-3">
                  <MDBInput
                    icon="pencil-alt"
                    label="Message"
                    iconClass="grey-text"
                    type="textarea"
                    id="message"
                    name="message"
                    value={state.message}
                    onChange={changeHandler}
                    className="form-control"
                    required
                  />
                  {/* <div className="invalid-feedback">
                    Please type your message.
                  </div> */}
                  {/* <div className="valid-feedback">Looks good!</div> */}
                </div>
                {/* <div className="md-form submit text-center">
                  <BootstrapButton
                    variant="contained"
                    color="primary"
                    disableRipple
                    size='large'
                    fullWidth={true}
                    type="submit"
                    className="containedSizeLarge"
                  >
                    Send
                  </BootstrapButton>
                </div> */}
                <div className="md-form submit text-center">
                  <Button
                    type="submit"
                    name="submit"
                    id="submit"
                    value="send"
                    variant="contained"
                    color="primary"
                    fullWidth="true"
                    size="large"
                    // onClick={submitHandler}
                  >
                    Send
                  </Button>
                </div>
                {/* <MDBBtn color="primary" type="submit">
                  Submit Form
                </MDBBtn> */}
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="7">
          <div
            id="map-container"
            className="rounded z-depth-1-half map-container"
            style={{ height: "400px" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2498.559355274686!2d22.696449015726614!3d51.22719257959005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4722546b7b1aac13%3A0x581e3e8087c5fcc5!2sWarsztatowa%2010%2C%2021-047%20%C5%9Awidnik!5e0!3m2!1sru!2spl!4v1600422539057!5m2!1sru!2spl%22"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2498.559355274686!2d22.696449015726614!3d51.22719257959005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4722546b7b1aac13%3A0x581e3e8087c5fcc5!2sWarsztatowa%2010%2C%2021-047%20%C5%9Awidnik!5e0!3m2!1sru!2spl!4v1600422539057!5m2!1sru!2spl%22"
              title="This is a unique title"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
            />
          </div>
          <br />
          <MDBRow className="text-center">
            <MDBCol md="4">
              <MDBBtn tag="a" floating color="blue" className="accent-1">
                <RoomIcon />
              </MDBBtn>
              <p>New York, 94126</p>
              <p className="mb-md-0">United States</p>
            </MDBCol>
            <MDBCol md="4">
              <MDBBtn tag="a" floating color="blue" className="accent-1">
                <PhoneIcon />
              </MDBBtn>
              <p>+ 01 234 567 89</p>
              <p className="mb-md-0">Mon - Fri, 8:00-22:00</p>
            </MDBCol>
            <MDBCol md="4">
              <MDBBtn tag="a" floating color="blue" className="accent-1">
                <MailIcon />
              </MDBBtn>
              <p>info@gmail.com</p>
              <p className="mb-md-0">sale@gmail.com</p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default ContactPage;
