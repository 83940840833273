import React from "react";

import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
// import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import './style.css'

const useStyles = makeStyles(() => ({
  icon: {
    display: "flex",
  },
  
}));

const Arrange = () => {
  const { dispatch } = React.useContext(ContextInput);
  // const [front, setFront] = useState(false)
  const classes = useStyles();

  const toFront = () => {
    dispatch({
      type: "ITEM_ARRANGE_FRONT",
    });
  };

  return (
    <div className={classes.icon}>
      <div>
        <Tooltip title="change layer" arrow>
          <IconButton color="primary" onClick={toFront}>
            <FlipToFrontIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>

      </div>
    </div>
  );
};

export default Arrange;
