const ReducerInput = (state, action, payload) => {
  switch (action.type) {
    case "ACTIVE_INPUTS":
      return {
        ...state,
        activeInput: action.payload.id,
      };
    case "CHANGE_INPUTS":
      state.inputs[action.payload.id].text = action.payload.value;
      return {
        ...state,
      };
    case "ADD_INPUT":
      const newInput = {
        id: state.inputs.length,
        text: `Test text ${state.inputs.length + 1}`,
        // text: 'Type your text',
        bold: false,
        style: false,
        underline: false,
        fontSize: 14,
        color: "#fff",
        activeFontFamily: "Open Sans",
        zIndex: false,
      };
      state.inputs.push(newInput);
      return {
        ...state,
      };

    case "CHAHGE_BOLD":
      state.inputs[state.activeInput].bold = !state.inputs[state.activeInput]
        .bold;
      return {
        ...state,
      };
      
    case "CHAHGE_STYLE":
      state.inputs[state.activeInput].style = !state.inputs[state.activeInput]
        .style;
      return {
        ...state,
      };
    case "CHAHGE_UNDERLINE":
      state.inputs[state.activeInput].underline = !state.inputs[
        state.activeInput
      ].underline;
      return {
        ...state,
      };
    case "CHAHGE_FONTSIZE":
      state.inputs[state.activeInput].fontSize = action.payload;
      return {
        ...state,
      };
    case "CHAHGE_COLOR":
      state.inputs[state.activeInput].color = action.payload.hex;
      return {
        ...state,
      };
    case "CHAHGE_BACKGROUND":
      state.bgColor = action.payload.hex;
      // console.log(action.payload.hex)
      return { ...state, bgColor: action.payload.hex };

    case "CHANGE_FONT":
      state.inputs[state.activeInput].activeFontFamily = action.payload;
      //   console.log(state);
      return {
        ...state,
      };
    case "GHANGE_BGCARD":
      state.bgCard = action.payload;
      return {
        ...state,
        bgColor: "gray",
      };
      case "CHECK_INPUT":
      state.disabledButtonInput = !state.disabledButtonInput;
      return {
        ...state,
      };
    case "IMAGE_UPLOAD":
      return {
        ...state,
        ImageUpload: {
          file: action.payload.file,
          imagePreviewUrl: action.payload.imagePreviewUrl,
        },
      };
    case "IMAGE_DELETE":
      // state.inputs[state.activeInput].delete = !state.inputs[state.activeInput].delete
      return {
        ...state,
        ImageUpload: {
          imagePreviewUrl: action.payload.imagePreviewUrl,
        },
      };
    case "ITEMS_DELETE":
      state.inputs.splice(action.payload.id, 1);
      console.log(action.payload.id);
      return {
        ...state,
        activeInput: action.payload.id + 1,
      };
    case "ITEM_ARRANGE_FRONT":
      state.inputs[state.activeInput].zIndex = !state.inputs[state.activeInput]
        .zIndex;
      return {
        ...state,
      };
    case "BORDER_HANDLER":
      state.activeInput = 100;
      return {
        ...state,
      };
      case "PROJECT_IMAGE":
      state.ProjectImages = action.payload;
      return {
        ...state,
        ProjectImages: action.payload
      };
      case "RENDER_IMAGE":
      state.RenderImages = action.payload;
      console.log(state)
      return {
        ...state,
        RenderImages: action.payload
      };
      case "QR_CODE":
      state.QRCode = action.payload;
      return {
        ...state,
      };
      case "QRCODE_DELETE":
      // state.inputs[state.activeInput].delete = !state.inputs[state.activeInput].delete
      return {
        ...state,
        QRCode: action.payload.QRCode
      };
    default:
      return state;
  }
};
export default ReducerInput;
