import React, { useContext } from 'react'
// import styled from 'styled-components'
import {ContextCalculator} from '../../context/Calculator/ContextCalculator'
// import classes from '../Select/Select.css'

//UI

import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';


export const SelectMaterial = () =>{
    const {state, dispatch} = useContext(ContextCalculator)
    const optionMaterial = state.optionMaterial
    
    const changeMaterial = (e) => {
    dispatch({
      type: 'CHANGE_MATERIAL',
      payload: e.target.value,
    })
  }
    
    return(
        
        <Select style={{display:'block', width:'168px', paddingRight:'0 !important', marginTop:10}} onChange={changeMaterial} defaultValue="DREAMCARD azure 460g jednostronny">
           {
               optionMaterial.map((optionItem, index) => {
                    return (
                    <MenuItem
                    key={index}
                    value={optionItem}>{optionItem}
                    </MenuItem>
                    )
               })
           }
        </Select>
    )
}

