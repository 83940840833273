import React from "react";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";

import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import "./stlye.css";
const DeleteButton = () => {
  const { state, dispatch } = React.useContext(ContextInput);
  // console.log(state)
  // const uploadImage = state.ImageUpload.imagePreviewUrl;
  const useStyles = makeStyles((theme) => ({
    color: {
      color: "#3f51b5",
    },
  }));
  const classes = useStyles();

  const deleteHandler = (e) => {
    e.preventDefault();
    dispatch({
      type: "QRCODE_DELETE",
      payload: {
        QRCode: "",
      },
    });
  };
  // const deleteItems = (e, remove, id, index) => {
  //   e.preventDefault();
  //   dispatch({
  //     type: "ITEMS_DELETE",
  //     payload: { remove, id, index },
  //   });
  // };

  const deleteItems = (e) => {
    e.preventDefault();
    dispatch({
      type: "ITEMS_DELETE",
      payload: {
        id: state.activeInput,
        // id: state.inputs.length
      },
    });
  };

  return (
    <div className="box">
      <div>
        <Tooltip title="delete QRcode" arrow>
          <IconButton
            aria-label="delete"
            className={classes.color}
            onClick={deleteHandler}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="delete text" arrow>
          <IconButton
            aria-label="delete"
            className={classes.color}
            onClick={deleteItems}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default DeleteButton;
