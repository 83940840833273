import React from "react";
import { Rnd } from "react-rnd";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import Draggable from "react-draggable";
import RenderImageItem from "../RenderImageItem/RenderImageItem";
const RenderImages = () => {
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);

  const projectImages = stateInputs.state.ProjectImages;
  // const renderImages = stateInputs.state.RenderImages;


  return (
    <>
      {projectImages &&
        projectImages.map((image, index) => {
          return (
            <RenderImageItem projectImages = {projectImages[index]} />
          );
        })}
    </>
  );
};

export default RenderImages;
