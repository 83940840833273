import React from "react";
import { NavLink } from "react-router-dom";
import Drawer from "../../modules/Drawer/Drawer";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";

import "./style.css";

const images = [
  {
    url: require("../../assets/img/cardMaster.jpg"),
    title: "Wizytówki",
    width: "33%",
    href: "http://creator.drukurs.pl/constructor",
  },
  {
    url: require("../../assets/img/diplomMaster.jpg"),
    title: "Dyplom",
    width: "33%",
    href: "http://dyplomy.drukurs.pl/constructor",
  },
  {
    url: require("../../assets/img/calendarMaster.png"),
    title: "Kalendarz",
    width: "34%",
    href: "http://kalendarze.drukurs.pl/constructor",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  image: {
    // marginTop: '3%',
    position: "relative",
    height: 500,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 220,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

export default React.memo(() => {
  const classes = useStyles();

  return (
    <div className="MainContainer">
      <Drawer />
      <div className="MainText">
        <div className="MainTitle">
          <h1>Home Page</h1>
        </div>
        <div className="MainSubTitle">
          <p>Simple to create</p>
        </div>
        {/* <div className="card__btn">
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="Link-btn"
            href="/constructor"
          >
            {/* <NavLink to="/constructor" className="Link-btn">
              Create
            </NavLink> */}
        {/* Create */}
        {/* </Button> */}
        {/* </div> */}
        {/* <div className="LinkB">
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="Link-btn"
            href="http://dyplomy.drukurs.pl/"
          >
            Dyplom
          </Button>
        </div> */}

        <div className={classes.root}>
          {images.map((image) => (
            <ButtonBase
              focusRipple
              key={image.title}
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              style={{
                width: image.width,
              }}
              href={image.href}
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
              
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {image.title}
                  <span className={classes.imageMarked} />
                </Typography>
              </span>
            </ButtonBase>
          ))}
        </div>
      </div>
    </div>
  );
});
