import React from "react";
import Drawer from "../../modules/Drawer/Drawer";
// import { RegisterForm } from "./register.form";
import ContactPage from './ContactPage'

export default React.memo(() => {

  return (
    // <FormThemeProvider>
    <div style={{
      width: '100vw',
      height: '100vh'
    }}>
      <Drawer />
      <ContactPage />
      {/* <RegisterForm /> */}
      {/* <FormsPage /> */}
    </div>
  );
});

{
  /* <form method="post" action="form.php" enctype="multipart/form-data">
<div class="form-group">
  <input
    type="text"
    name="name"
    class="form-control"
    value=""
    placeholder="Name"
    required=""
  />
</div>
<div class="form-group">
  <input
    type="email"
    name="email"
    class="form-control"
    value=""
    placeholder="Email address"
    required=""
  />
</div>
<div class="form-group">
  <input
    type="text"
    name="subject"
    class="form-control"
    value=""
    placeholder="Subject"
    required=""
  />
</div>
<div class="form-group">
  <textarea
    name="message"
    class="form-control"
    placeholder="Write your message here"
    required=""
  ></textarea>
</div>
<div class="form-group">
  <input type="file" name="attachment" class="form-control" />
</div>
<div class="submit">
  <input type="submit" name="submit" class="btn" value="SUBMIT" />
</div>
</form> */
}

// {/* <label for="message">Message</label> */}
// <TextareaAutosize name="message" id="message" placeholder="message"></TextareaAutosize>
// {/* <label for="email">email</label>{" "} */}
// <Input name="email" id="email" placeholder="email"></Input>
// {/* <label for="file">File</label>{" "} */}
{
  //  <input type="file" name="file" id="file" />
}
